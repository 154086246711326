import React from "react"
import PcpItem from "../../components/PcpItem"
import PcpPromo from "../../components/PcpPromo"
import "./style.scss"

const PcpList = ({ products, promos }) => {
  return (
    <div className="pcp-items-container pcp-items"> 
      {products?.map((item, index) => {
        let pcpItem = [<PcpItem {...item} key={index} />]

        const promo = promos?.find(promo => promo.index == index)
        if (promo) { pcpItem.push(<PcpPromo {...promo} />) }

        return(pcpItem)
      })}
    </div>
  )
}

export const formatPcpItem = (product, option, optionIndex) => {
  const title = option?.title?.replace(/6pk|12pk|24pk|30pk|90pk/, '')?.trim()
  return({
    title,
    optionIndex,
    feature: null,
    originalPrice: `$${option.price}`,
    price: `$${option.sale_price}`,
    product: product.value,
    image: option.image,
    hoverImage: option.hoverImage,
    type: 'avg',
    packSize: option.quantity,
    brand: product.brand,
    lensType: product.lensType,
    value: option.value,
  })
}

export const filterLensProducts = (products, filter) => {
  if (filter?.brand?.length) {
    products = products.filter(product => {
      return filter?.brand?.some(brand => {
        return product.brand == brand || product?.title?.toLowerCase()?.includes(brand?.toLowerCase())
      })
    })
  }

  if (filter?.lensType?.length) {
    products = products.filter(product => {
      return filter?.lensType?.some(type => {
        return product?.lensType?.includes(type) || (product.title.includes('Hubble') && type == 'daily')
      })
    })
  }
  
  return products
}

export default PcpList
